// Copyright 2015-2020 SWIM.AI inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {AnyDateTime, DateTime} from "./DateTime";
import {Item, Value, Text, Form} from "@swim/structure";

/** @hidden */
export class DateTimeForm extends Form<DateTime, AnyDateTime> {
  private readonly _unit: DateTime | undefined;

  constructor(unit?: DateTime) {
    super();
    this._unit = unit;
  }

  unit(): DateTime | undefined;
  unit(unit: DateTime | undefined): Form<DateTime, AnyDateTime>;
  unit(unit?: DateTime | undefined): DateTime | undefined | Form<DateTime, AnyDateTime> {
    if (arguments.length === 0) {
      return this._unit !== void 0 ? this._unit : new DateTime(0);
    } else {
      return new DateTimeForm(unit);
    }
  }

  mold(date: AnyDateTime): Item {
    date = DateTime.fromAny(date);
    return Text.from(date.toString());
  }

  cast(value: Value): DateTime | undefined {
    let date: DateTime | undefined;
    try {
      date = DateTime.fromValue(value);
      if (!date) {
        const millis = value.numberValue(void 0);
        if (millis !== void 0) {
          date = new DateTime(millis);
        } else {
          const string = value.stringValue(void 0);
          if (string !== void 0) {
            date = DateTime.parse(string);
          }
        }
      }
    } catch (e) {
      // swallow
    }
    return date;
  }
}
DateTime.Form = DateTimeForm;
