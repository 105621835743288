// Copyright 2015-2020 SWIM.AI inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {GraphicViewController} from "@swim/view";
import {MapProjection} from "./MapProjection";
import {MapViewContext} from "./MapViewContext";
import {MapGraphicView} from "./MapGraphicView";
import {MapGraphicViewObserver} from "./MapGraphicViewObserver";

export class MapGraphicViewController<V extends MapGraphicView = MapGraphicView> extends GraphicViewController<V> implements MapGraphicViewObserver<V> {
  get projection(): MapProjection | null {
    const view = this._view;
    return view ? view.projection : null;
  }

  get zoom(): number {
    const view = this._view;
    return view ? view.zoom : 0;
  }

  viewWillProject(viewContext: MapViewContext, view: V): void {
    // hook
  }

  viewDidProject(viewContext: MapViewContext, view: V): void {
    // hook
  }
}
